import React, { Component } from 'react';
import axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import Category from './category';
import List from './list/list';
import Two from './list/two';
import Large from './list/large';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Loader from '../general/loader';
import Subcategory from './subcategory';

class Menu extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        categories: [],
        selectCategory: [],
        items: [],
        loadCat: false,
        load: false,
        listview: "large",
    }

    async componentDidMount(){

        // Get Items
        let id = window.location.pathname.replace("/menu/", "");

        // Get categories
        const {data} = await axios.get("https://manage.caviarmenu.com/api/categories/rossel");
        this.setState({ categories: data }, ()=>{
            this.setState({loadCat: true});
            this.getCategoryName(id);
        });

        {
            const {data} = await axios.get("https://manage.caviarmenu.com/api/items/rossel");
            this.setState({ items: data }, ()=>{
                this.setState({load: true});
            });
        }

        this.layoutDirection();

    }

    layoutDirection() {
        if(this.props.Language != "en")
            document.getElementById("itemContainer").style.direction = "rtl";
        else
            document.getElementById("itemContainer").style.direction = "ltr";
    }

    getCategoryName(id) {
        if(id != -1)
            this.state.categories.map(cat => {
                if(cat.id == id) this.setState({ selectCategory: cat });
            });
        else
            this.setState({ selectCategory: this.state.categories[0] });
    }

    handleChange = async e => {
        await this.props.handleLangaugeSubmit(e.target.value);
        this.layoutDirection();
    }

    handleCategory = async catId => {
        this.setState({load: false});
        this.getCategoryName(catId);
        this.setState({load: true});
    }

    handleListview = style => {
        this.setState({listview: style});
    }

    headCover = {'backgroundImage':`url('/assets/rosel.jpg')`, 'backgroundSize':'cover'}



    render() {

        return (
            <div className='menu-items'>
                <div className="head" style={this.headCover} >
                        <Link to={"/category"}>
                            <i  className="backward fa-solid fa-arrow-left"></i>
                        </Link>
                        
                        <select name="language"
                            value={this.props.Language}
                            onChange={this.handleChange} 
                        >
                            <option value="krd">کوردی</option>
                            <option value="ar">عربی</option>
                            <option value="en">English</option>
                        </select>                        
                    <img src={this.settings.logo} alt="" />
                </div>

                <div className='categories'>

                    {
                        this.state.loadCat ?
                        <> 
                            { this.state.categories.map(cat => <Category key={cat.id}  Data = {cat} Language = {this.props.Language} handleCategory = { this.handleCategory} Active = {this.state.selectCategory.id == cat.id} ></Category>) }
                        </>
                        : <Loader></Loader>
                    }
                    
                </div>

                <div className='items' id='itemContainer'>

                <div className="head-listview">
                    <h5 className="category-name">
                    {
                        this.props.Language == "en" ? this.state.selectCategory.name_eng
                        : this.props.Language == "krd" ? this.state.selectCategory.name_krd
                        : this.state.selectCategory.name_ar
                    }
                    </h5>

                    <div className="listview">
                        <i role="button" className={`fas fa-th-list ${this.state.listview == "list"?'active':''}`} onClick={ () => this.handleListview("list")}></i>
                        <i role="button" className={`fas fa-th-large ${this.state.listview == "two"?'active':''}`} onClick={ () => this.handleListview("two")}></i>
                        <i role="button" className={`far fa-square ${this.state.listview == "large"?'active':''}`} onClick={ () => this.handleListview("large")}></i>
                    </div>

                </div>

                {
                    this.state.load ?
                    <> 
                        {
                        this.state.selectCategory.subCat 
                        ? 
                            <div className="subcategories">
                                {
                                    this.state.selectCategory.subCategories.map(
                                        item => <Subcategory key={item.id} Language = {this.props.Language} ListView = {this.state.listview} Category = {item} Items = {this.state.items[item.id]} />
                                    )
                                }
                            </div>
                        :
                        this.state.listview == "list" ? this.state.items[this.state.selectCategory.id].map(item => <List key={item.id}  Data = {item} Language = {this.props.Language} ></List> )
                            : this.state.listview == "two" ? <div className='style-two'> { this.state.items[this.state.selectCategory.id].map(item => <Two key={item.id}  Data = {item} Language = {this.props.Language} ></Two> ) } </div>
                            : <div className='style-large'> { this.state.items[this.state.selectCategory.id].map(item => <Large key={item.id}  Data = {item} Language = {this.props.Language} ></Large> ) } </div>
                        }
                    </>
                    : <Loader></Loader>
                }
                </div>
                
            </div>
        );
    }
}

export default Menu;